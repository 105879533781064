$primary: #094151;
$control-grey: #DFE6E8;
$theme-colors: ( 
    "primary": $primary,
    "control-grey": $control-grey,
    "warning": #ffc107,
    "light-grey": #f6f6f6,
    "adh-orange": #ff6f47,
    "adh-brown": #c2b59b,
    "adh-red": #e51c23,
);
$border-radius: 6px;

@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;0,600;1,700&display=swap');
html {
    font-size: 0.8rem;
}

body {
    font-family: 'Work Sans', sans-serif;
    // background: #eceff0;
    background: #dfe2e2;
    color: $primary;
    min-height: 100vh;
    position: relative;

}

.search-options {
    position: absolute;
    overflow-x: hidden;
    z-index: 9999;
}

.search-options ul {
    list-style: none;
    padding: 0.5em;
    background-color: #fff;
    border-radius: 0px 0px 5px 5px;

    li {
        padding: 0.5em;
        border-bottom: 1px solid #DFE6E8;

        &:hover {
                background: #DFE6E8;
                cursor: pointer;
            }
    }
}

.temperature-card {
    font-size: 3em;
    
}

.precip-table {
    width: 100%;
    height: 100px;
}

.chart-container {
    width: 100%;
}

.card {
    border: 1px solid rgba(0,0,0,0.1) !important;
}

.card-header {
    background-color: #e8eef7;
    border-bottom: none;
}

.rv-hint {
    z-index: 99999;
}

.hintBox {
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    padding: 1em;
    font-size: 12px;
    color: #333;
    border-radius: 5px;
    opacity: 0.95;
}

.hintBox.wide {
    min-width: 400px;
}

.badge-pill {
    display: inline-block;
    border-radius: 5px;
    padding: 0.1em 0.5em;
    font-weight: bold;
    height: 22px;
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
}

.hint-badge-pill {
    margin-bottom: 1em;
    color: #fff;
}